import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';

const WhatsappConnect = () => {

  const whatsappNumber = '+917045420139';
  const whatsappMessage = 'Hi, I want to know about your pest control services. Can you provide more information?';
  const [whatsappUrl, setWhatsappUrl] = useState('');

  // Detect device type and set the WhatsApp URL accordingly
  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const baseUrl = isMobile ? 'whatsapp://send' : 'https://web.whatsapp.com/send';
    setWhatsappUrl(`${baseUrl}?phone=${whatsappNumber}&text=${encodeURIComponent(whatsappMessage)}`);
  }, [whatsappNumber, whatsappMessage]);

  return (
    <div className="fixed z-[9999] bottom-10 right-4"><Link to={whatsappUrl} target="_blank" rel="noopener noreferrer" className="z-50 text-white p-3 rounded-full shadow-lg transition duration-300"><img src="https://cdn-icons-png.flaticon.com/512/3670/3670051.png" alt="WhatsApp" className="w-10 h-10"/></Link></div>
  )
}

export default WhatsappConnect