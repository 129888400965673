import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="relative w-full h-full">
      <div className="px-4  py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl  md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#C0232C] text-white">
              About
            </p>
          </div>
          <h2  data-aos="fade-up"  className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            About Us
          </h2>
        </div>
      </div>

      <div></div>

      <div className="text-center  px-7 xl:px-40">
        <div className="flex flex-wrap items-center text-left text-center">
          <div  data-aos="fade-left"  className="w-full md:w-3/5 lg:w-1/2 px-4">
            <img
              src="https://picsum.photos/400/240"
              alt="gem"
              className="inline-block rounded shadow-lg border border-merino-400"
            />
          </div>
          <div  data-aos="fade-right"  className="w-full md:w-2/5 lg:w-1/2 px-4 text-center md:text-left lg:pl-12">
            <p className="sm:text-lg mt-6">
              Welcome to the Best Pest Control Service Provider in Mumbai, your
              primary source for premium and affordably priced pest control
              services in your area. With an unwavering dedication to highest
              standards and an aptitude for controlling insect infestations, we
              endeavor towards offering efficient solutions that are suited to
              your individual requirements.
            </p>

            <p className="sm:text-lg mt-6">
              {" "}
              We understand the discomfort and discomfort that pests may cause
              in your home or company. Whether you're suffering with challenging
              bed bugs invading your bedroom or relentless cockroaches in your
              kitchen, we have the knowledge and equipment to handle any pest
              problem head on.
            </p>
            <p className="sm:text-lg mt-6">
              {" "}
              Our team of expert pest exterminators is committed to providing
              immediate and efficient service, so that your home is pest-free as
              soon as possible. We use cutting-edge procedures and
              industry-leading solutions to exterminate pests while causing
              minimal disruption to your everyday routine.
            </p>
          </div>
        </div>

        <div className="flex flex-wrap items-center mt-20 text-left text-center">
          <div  data-aos="fade-left"  className="w-full md:w-3/5 lg:w-1/2 px-4">
            <img
              src="https://picsum.photos/400/240"
              alt="project members"
              className="inline-block rounded shadow-lg border border-merino-400"
            />
          </div>
          <div  data-aos="fade-right"  className="w-full md:w-2/5 lg:w-1/2 px-4 md:order-first text-center md:text-left lg:pr-12">
            <p className="sm:text-lg mt-6">
              From termite treatments to mosquito spraying, we provide a full
              spectrum of pest control services in Mumbai, India to solve any
              infestation problem you may experience. Our bed bug treatments are
              specifically intended to eliminate these stubborn pests from your
              bedding and furnishings, giving you peace of mind and a good
              night's sleep.
            </p>
            <p className="sm:text-lg mt-6">
              Are you concerned about the usage of harmful chemicals? We also
              provide herbal pest control solutions for clients who are
              environmentally conscious looking for safer alternatives. Our
              herbal solutions are made from natural materials and provide
              excellent pest control without harming your well-being or the
              surroundings.{" "}
            </p>
            <p className="sm:text-lg mt-6">
              {" "}
              Whether you have a termite infestation at home or require
              commercial pest control services for your business, our Pest
              Control services are the best option. Our affordable prices and
              honest methodology guarantee that you get the best value for your
              pest control investment.
            </p>
          </div>
        </div>
      </div>

      <div>
        <div  data-aos="fade-up"  className="relative mx-auto max-w-5xl text-center">
          <p className="mx-auto my-4 w-full max-w-xl bg-transparent text-center font-medium text-gray-800">
            Do not let pests take over your space. Contact us or Search "Pest
            Control Near Me" immediately to set up a consultation and take the
            first step towards a pest-free environment. Trust us to deliver
            trustworthy and excellent solutions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
