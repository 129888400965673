import React, { useEffect } from "react";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
    <div className="py-20 md:py-28 px-7 xl:px-40 overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap xl:items-center -mx-4">
            <div data-aos="fade-right" className="w-full md:w-1/2 px-4 mb-16 md:mb-0">
              {/* <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-white bg-green-500 uppercase rounded-9xl">Header</span> */}
              <h1 className="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tight">
                Best Pest Control service in Mumbai, India
              </h1>
              <p className="mb-8 text-lg md:text-xl text-coolGray-500 font-medium">
                Keep your family safe from rodents and insects
              </p>
              <div className="flex flex-wrap">
                <div className="w-full md:w-auto py-1 md:py-0 md:mr-4">
                  <div className="flex h-12 justify-center gap-x-6 dark:text-white">
                    <a
                      className="group flex h-min items-center disabled:opacity-50 disabled:hover:opacity-50 hover:opacity-95 justify-center ring-none rounded-lg shadow-lg font-semibold py-2 px-4 font-dm focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-[#791216] border-b-violet-700 disabled:border-0 disabled:bg-violet-500 disabled:text-white ring-white text-white border-b-4 hover:border-0 active:border-0 hover:text-gray-100 active:bg-violet-800 active:text-gray-300 focus-visible:outline-violet-500 text-sm sm:text-base dark:bg-[#791216] dark:border-gray-700 dark:border-b-gray-900"
                      href=""
                    >
                      Contact us
                    </a>
                    <a
                      className="group flex h-min ring-none items-center justify-center hover:opacity-95 disabled:opacity-50 rounded-lg py-2 px-4 font-dm focus:outline-none !ring-transparent text-violet-800 border border-violet-500 border-b-violet-400 border-b-4 hover:border active:border bg-white hover:text-violet-900 hover:bg-gray-50 active:bg-gray-100 active:text-violet-600 focus-visible:outline-violet-600 focus-visible:ring-violet-700 text-sm sm:text-base dark:bg-[#791216] dark:border-[#791216] dark:border-b-gray-900 dark:text-white"
                      href=""
                    >
                      {/* <svg aria-hidden="true" className="h-3 w-3 flex-none fill-violet-900 group-active:fill-current">
            <path
                d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z">
            </path>
        </svg> */}
                      <span className="ml-3">Lets connect</span>
                    </a>
                  </div>
                </div>
                {/* <div className="w-full md:w-auto py-1 md:py-0"><a className="inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-coolGray-800 font-medium text-center bg-white hover:bg-coolGray-100 focus:ring-2 focus:ring-coolGray-200 focus:ring-opacity-50 border border-coolGray-200 rounded-md shadow-sm" href="#">Sign Up</a></div> */}
              </div>
            </div>
            <div data-aos="fade-left" className="w-full md:w-1/2 px-4">
              <div className="relative mx-auto md:mr-0 max-w-max">
                <img
                  className="absolute z-10 -left-14 -top-12 w-28 md:w-auto"
                  src="flex-ui-assets/elements/circle3-yellow.svg"
                  alt=""
                />
                <img
                  className="absolute z-10 -right-7 -bottom-8 w-28 md:w-auto"
                  src="flex-ui-assets/elements/dots3-blue.svg"
                  alt=""
                />
                <img
                  className="relative rounded-3xl"
                  src="https://template.creativemox.com/guardibugs/wp-content/uploads/sites/27/2024/07/exterminator-in-protective-mask-holding-toxic-equipment-near-kitchen-cabinet-1536x1025.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="relative pt-44 overflow-hidden px-7 xl:px-40">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap -m-6">
            <div data-aos="fade-up" className="w-full lg:w-1/2 p-6">
              <div className="bg-[#C0232C] mx-auto max-w-lg h-96 rounded-3xl">
                {/* <img className="relative top-10 mx-auto transform hover:-translate-y-16 transition ease-in-out duration-500" src="https://template.creativemox.com/guardibugs/wp-content/uploads/sites/27/2024/07/man-in-protective-mask-and-uniform-pointing-with-f.png" alt=""/> */}
                <img
                  className="relative top-10 mx-auto transform hover:-translate-y-16 transition ease-in-out duration-500"
                  src="https://template.creativemox.com/guardibugs/wp-content/uploads/sites/27/2024/07/exterminator-in-protective-mask-and-uniform-holdin-2-1.png"
                  alt=""
                />
              </div>
            </div>
            <div data-aos="fade-up" className="w-full lg:w-1/2 p-6">
              <div className="lg:max-w-lg">
                <h2 className="mb-6 font-heading font-bold text-7xl text-gray-900">
                  {" "}
                  Why Aniruddha Pest Control Is the Best at Pest Management
                </h2>
                <p className="mb-20 text-base text-gray-600">
                  Keeping an environment free of pests requires choosing a
                  trustworthy and efficient service provider in the field of
                  pest control. You need look no further than Aniruddha Pest
                  Control if you're looking for "pest control near me" or "pest
                  exterminator near me." Why Aniruddha is different from the
                  rest of the pack is as follows:
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" bg-white overflow-hidden relative">
        <div className="pt-16 pb-24 px-5 xs:px-8 xl:px-12 px-7 xl:px-40 bg-teal-900 ">
          <div className="container mx-auto px-4">
            {/* <div className="flex mb-4 items-center">
        <svg width="8" height="8" viewbox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4" fill="#C0232C"></circle></svg>
        <span className="inline-block ml-2 text-sm font-medium text-white">Solutions</span>
      </div> */}
            <div className=" border-white border-opacity-25 ">
              <h1 data-aos="fade-right" className="font-heading text-4xl sm:text-6xl text-white mb-24">
                Tailored Pest Control Solutions
              </h1>
              <div className="flex flex-wrap -mx-4">
                <div data-aos="fade-up" className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-12 lg:mb-0">
                  <div  className="flex flex-col h-full">
                    <svg
                      width="48"
                      height="48"
                      viewbox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
                        fill="#ffff"
                      ></path>
                      <circle cx="16" cy="16" r="4" fill="#022C22"></circle>
                      <circle cx="24" cy="32" r="4" fill="#022C22"></circle>
                      <circle cx="32" cy="16" r="4" fill="#022C22"></circle>
                    </svg>
                    <h5 className="text-2xl font-medium text-white mt-6 mb-3">
                      Personalized Solutions
                    </h5>
                    <p className="text-white opacity-80 mb-6">
                      Whether it's termites, bed bugs, or other general insects,
                      we offer individualized approaches that suit individual
                      needs because we recognize that every pest infestation is
                      different.
                    </p>
                    {/* <a href="#" className="inline-block mt-auto text-lg font-medium text-white hover:text-lime-500">Read more</a> */}
                  </div>
                </div>
                <div data-aos="fade-up" className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-12 lg:mb-0">
                  <div className="flex flex-col h-full">
                    <svg
                      width="48"
                      height="48"
                      viewbox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
                        fill="#ffff"
                      ></path>
                      <rect
                        x="23"
                        y="8"
                        width="2"
                        height="12"
                        rx="1"
                        fill="#022C22"
                      ></rect>
                      <rect
                        x="23"
                        y="28"
                        width="2"
                        height="12"
                        rx="1"
                        fill="#022C22"
                      ></rect>
                      <rect
                        x="34.6066"
                        y="11.9792"
                        width="2"
                        height="12"
                        rx="1"
                        transform="rotate(45 34.6066 11.9792)"
                        fill="#022C22"
                      ></rect>
                      <rect
                        x="20.4645"
                        y="26.1213"
                        width="2"
                        height="12"
                        rx="1"
                        transform="rotate(45 20.4645 26.1213)"
                        fill="#022C22"
                      ></rect>
                      <rect
                        x="28"
                        y="25"
                        width="2"
                        height="12"
                        rx="1"
                        transform="rotate(-90 28 25)"
                        fill="#022C22"
                      ></rect>
                      <rect
                        x="8"
                        y="25"
                        width="2"
                        height="12"
                        rx="1"
                        transform="rotate(-90 8 25)"
                        fill="#022C22"
                      ></rect>
                      <rect
                        x="26.1213"
                        y="27.5356"
                        width="2"
                        height="12"
                        rx="1"
                        transform="rotate(-45 26.1213 27.5356)"
                        fill="#022C22"
                      ></rect>
                      <rect
                        x="11.9792"
                        y="13.3936"
                        width="2"
                        height="12"
                        rx="1"
                        transform="rotate(-45 11.9792 13.3936)"
                        fill="#022C22"
                      ></rect>
                    </svg>
                    <h5 className="text-2xl font-medium text-white mt-6 mb-3">
                      Comprehensive Services
                    </h5>
                    <p className="text-white opacity-80 mb-6">
                      We provide a wide range of services using state-of-the-art
                      methods for broad and durable outcomes, from bed bug
                      treatment to termite control.
                    </p>
                    {/* <a href="#" className="inline-block mt-auto text-lg font-medium text-white hover:text-lime-500">Read more</a> */}
                  </div>
                </div>
                <div data-aos="fade-up" className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-12 sm:mb-0">
                  <div className="flex flex-col h-full">
                    <svg
                      width="48"
                      height="48"
                      viewbox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
                        fill="#ffff"
                      ></path>
                      <path
                        d="M25 24C25 24.5523 24.5523 25 24 25C23.4477 25 23 24.5523 23 24C23 23.4477 23.4477 23 24 23C24.5523 23 25 23.4477 25 24Z"
                        fill="#022C22"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24 25C24.5523 25 25 24.5523 25 24C25 23.4477 24.5523 23 24 23C23.4477 23 23 23.4477 23 24C23 24.5523 23.4477 25 24 25Z"
                        fill="#022C22"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M40 23C40.5523 23 41 23.4477 41 24C41 33.3888 33.3888 41 24 41C23.4477 41 23 40.5523 23 40C23 39.4477 23.4477 39 24 39C32.2843 39 39 32.2843 39 24C39 23.4477 39.4477 23 40 23Z"
                        fill="#022C22"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24 9C15.7157 9 9 15.7157 9 24C9 24.5523 8.55228 25 8 25C7.44772 25 7 24.5523 7 24C7 14.6112 14.6112 7 24 7C24.5523 7 25 7.44772 25 8C25 8.55228 24.5523 9 24 9Z"
                        fill="#022C22"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M36 23C36.5523 23 37 23.4477 37 24C37 31.1797 31.1797 37 24 37C23.4477 37 23 36.5523 23 36C23 35.4477 23.4477 35 24 35C30.0751 35 35 30.0751 35 24C35 23.4477 35.4477 23 36 23Z"
                        fill="#022C22"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24 13C17.9249 13 13 17.9249 13 24C13 24.5523 12.5523 25 12 25C11.4477 25 11 24.5523 11 24C11 16.8203 16.8203 11 24 11C24.5523 11 25 11.4477 25 12C25 12.5523 24.5523 13 24 13Z"
                        fill="#022C22"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M32 23C32.5523 23 33 23.4477 33 24C33 28.9706 28.9706 33 24 33C23.4477 33 23 32.5523 23 32C23 31.4477 23.4477 31 24 31C27.866 31 31 27.866 31 24C31 23.4477 31.4477 23 32 23Z"
                        fill="#022C22"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24 17C20.134 17 17 20.134 17 24C17 24.5523 16.5523 25 16 25C15.4477 25 15 24.5523 15 24C15 19.0294 19.0294 15 24 15C24.5523 15 25 15.4477 25 16C25 16.5523 24.5523 17 24 17Z"
                        fill="#022C22"
                      ></path>
                    </svg>
                    <h5 className="text-2xl font-medium text-white mt-6 mb-3">
                      Expertise in Termite Treatment
                    </h5>
                    <p className="text-white opacity-80 mb-6">
                      With preventative measures like spraying and anti-termite
                      barriers, our expertise in termite treatment and
                      anti-termite solutions guarantees protection against
                      damage to assets.
                    </p>
                    {/* <a href="#" className="inline-block mt-auto text-lg font-medium text-white hover:text-lime-500">Read more</a> */}
                  </div>
                </div>
                <div data-aos="fade-up" className="w-full sm:w-1/2 lg:w-1/3 px-4">
                  <div className="flex flex-col h-full">
                    <svg
                      width="48"
                      height="48"
                      viewbox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
                        fill="#ffff"
                      ></path>
                      <path
                        d="M23.8425 12.3779C23.9008 12.238 24.0992 12.238 24.1575 12.3779L30.1538 26.7692C31.9835 31.1605 28.7572 36 24 36Lnan nanL24 36C19.2428 36 16.0165 31.1605 17.8462 26.7692L23.8425 12.3779Z"
                        fill="#022C22"
                      ></path>
                    </svg>
                    <h5 className="text-2xl font-medium text-white mt-6 mb-3">
                      Environmentally Friendly Techniques
                    </h5>
                    <p className="text-white opacity-80 mb-6">
                      We put safety first and use eco-friendly techniques in all
                      of our treatments to minimize environmental damage and
                      eradicate pests
                    </p>
                    {/* <a href="#" className="inline-block mt-auto text-lg font-medium text-white hover:text-lime-500">Read more</a> */}
                  </div>
                </div>

                <div data-aos="fade-up" className="w-full sm:w-1/2 lg:w-1/3 px-4">
                  <div className="flex flex-col h-full">
                    <svg
                      width="48"
                      height="48"
                      viewbox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
                        fill="#ffff"
                      ></path>
                      <path
                        d="M23.8425 12.3779C23.9008 12.238 24.0992 12.238 24.1575 12.3779L30.1538 26.7692C31.9835 31.1605 28.7572 36 24 36Lnan nanL24 36C19.2428 36 16.0165 31.1605 17.8462 26.7692L23.8425 12.3779Z"
                        fill="#022C22"
                      ></path>
                    </svg>
                    <h5 className="text-2xl font-medium text-white mt-6 mb-3">
                      Quick and Reliable Service
                    </h5>
                    <p className="text-white opacity-80 mb-6">
                      You can rely on our highly trained employees to provide
                      swift and dependable service, taking care of your pest
                      problems in a way that will satisfy you.
                    </p>
                    {/* <a href="#" className="inline-block mt-auto text-lg font-medium text-white hover:text-lime-500">Read more</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="realative overflow-hidden h-full w-full ">
        <section className="relative z-10 overflow-hidden bg-[#C0232C] py-16 px-8  xl:px-40">
          <div className="container">
            <div className="-mx-4 flex flex-wrap items-center">
              <div data-aos="fade-right" className="w-full px-4 lg:w-1/2">
                <div className="text-center lg:text-left ">
                  <div className="mb-10 lg:mb-0 ">
                    <h1 className="mt-0 mb-3 text-3xl font-bold leading-tight sm:text-4xl sm:leading-tight md:text-[40px] md:leading-tight text-white ">
                      Protect Your Home – Call Us Now
                    </h1>
                    <p className="w-full text-base font-medium leading-relaxed sm:text-lg sm:leading-relaxed text-white"></p>
                  </div>
                </div>
              </div>
              <div data-aos="fade-left" className="w-full px-4 lg:w-1/2">
                <div className="text-center lg:text-right">
                  <a
                    className="font-semibold rounded-lg mx-auto inline-flex items-center justify-center bg-white py-4 px-9 hover:bg-opacity-90"
                    href="#"
                  >
                    Call Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <span className="absolute top-0 right-0 -z-10">
            <svg
              width="388"
              height="250"
              viewBox="0 0 388 220"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.05"
                d="M203 -28.5L4.87819e-05 250.5L881.5 250.5L881.5 -28.5002L203 -28.5Z"
                fill="url(#paint0_linear_971_6910)"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_971_6910"
                  x1="60.5"
                  y1="111"
                  x2="287"
                  y2="111"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.520507" stop-color="white"></stop>
                  <stop offset="1" stop-color="white" stop-opacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="absolute top-0 right-0 -z-10">
            <svg
              width="324"
              height="250"
              viewBox="0 0 324 220"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.05"
                d="M203 -28.5L4.87819e-05 250.5L881.5 250.5L881.5 -28.5002L203 -28.5Z"
                fill="url(#paint0_linear_971_6911)"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_971_6911"
                  x1="60.5"
                  y1="111"
                  x2="287"
                  y2="111"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.520507" stop-color="white"></stop>
                  <stop offset="1" stop-color="white" stop-opacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="absolute top-4 left-4 -z-10">
            <svg
              width="43"
              height="56"
              viewBox="0 0 43 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <circle
                  cx="40.9984"
                  cy="1.49626"
                  r="1.49626"
                  transform="rotate(90 40.9984 1.49626)"
                  fill="white"
                ></circle>
                <circle
                  cx="27.8304"
                  cy="1.49626"
                  r="1.49626"
                  transform="rotate(90 27.8304 1.49626)"
                  fill="white"
                ></circle>
                <circle
                  cx="14.6644"
                  cy="1.49626"
                  r="1.49626"
                  transform="rotate(90 14.6644 1.49626)"
                  fill="white"
                ></circle>
                <circle
                  cx="1.49642"
                  cy="1.49626"
                  r="1.49626"
                  transform="rotate(90 1.49642 1.49626)"
                  fill="white"
                ></circle>
                <circle
                  cx="40.9984"
                  cy="14.6642"
                  r="1.49626"
                  transform="rotate(90 40.9984 14.6642)"
                  fill="white"
                ></circle>
                <circle
                  cx="27.8304"
                  cy="14.6642"
                  r="1.49626"
                  transform="rotate(90 27.8304 14.6642)"
                  fill="white"
                ></circle>
                <circle
                  cx="14.6644"
                  cy="14.6642"
                  r="1.49626"
                  transform="rotate(90 14.6644 14.6642)"
                  fill="white"
                ></circle>
                <circle
                  cx="1.49642"
                  cy="14.6642"
                  r="1.49626"
                  transform="rotate(90 1.49642 14.6642)"
                  fill="white"
                ></circle>
                <circle
                  cx="40.9984"
                  cy="27.8302"
                  r="1.49626"
                  transform="rotate(90 40.9984 27.8302)"
                  fill="white"
                ></circle>
                <circle
                  cx="27.8304"
                  cy="27.8302"
                  r="1.49626"
                  transform="rotate(90 27.8304 27.8302)"
                  fill="white"
                ></circle>
                <circle
                  cx="14.6644"
                  cy="27.8302"
                  r="1.49626"
                  transform="rotate(90 14.6644 27.8302)"
                  fill="white"
                ></circle>
                <circle
                  cx="1.49642"
                  cy="27.8302"
                  r="1.49626"
                  transform="rotate(90 1.49642 27.8302)"
                  fill="white"
                ></circle>
                <circle
                  cx="40.9984"
                  cy="40.9982"
                  r="1.49626"
                  transform="rotate(90 40.9984 40.9982)"
                  fill="white"
                ></circle>
                <circle
                  cx="27.8304"
                  cy="40.9963"
                  r="1.49626"
                  transform="rotate(90 27.8304 40.9963)"
                  fill="white"
                ></circle>
                <circle
                  cx="14.6644"
                  cy="40.9982"
                  r="1.49626"
                  transform="rotate(90 14.6644 40.9982)"
                  fill="white"
                ></circle>
                <circle
                  cx="1.49642"
                  cy="40.9963"
                  r="1.49626"
                  transform="rotate(90 1.49642 40.9963)"
                  fill="white"
                ></circle>
                <circle
                  cx="40.9984"
                  cy="54.1642"
                  r="1.49626"
                  transform="rotate(90 40.9984 54.1642)"
                  fill="white"
                ></circle>
                <circle
                  cx="27.8304"
                  cy="54.1642"
                  r="1.49626"
                  transform="rotate(90 27.8304 54.1642)"
                  fill="white"
                ></circle>
                <circle
                  cx="14.6644"
                  cy="54.1642"
                  r="1.49626"
                  transform="rotate(90 14.6644 54.1642)"
                  fill="white"
                ></circle>
                <circle
                  cx="1.49642"
                  cy="54.1642"
                  r="1.49626"
                  transform="rotate(90 1.49642 54.1642)"
                  fill="white"
                ></circle>
              </g>
            </svg>
          </span>
        </section>
      </div>
    </div>
  )
}

export default Home