import React from "react";
import { Route, Router, Routes } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Layout from "./Layout";
import ContactUs from "../pages/ContactUs";

const Routing = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutUs />} />
      </Route>
    </Routes>
  );
};

export default Routing;
