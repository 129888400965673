import React, { useState } from 'react';
import { Link } from "react-router-dom";

const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <section className="overflow-hidden sticky top-0 z-50">
      <div className="flex items-center justify-between px-7 xl:px-40 py-5 bg-white shadow">
        <div className="w-auto">
          <Link className="text-2xl font-semibold text-[#134E4A] flex items-center justify-center" to="/">
          <img src='./favicon.jpg' alt='logo' className='w-auto h-9 mr-2' />
            <span className="text-[#C0232C] font-bold mr-2">Aniruddha</span> Pest Control
          </Link>
        </div>

        <div className="w-auto">
          <div className="flex items-center">
            <div className="hidden lg:block">
              <ul className="flex items-center mr-16 space-x-9 font-medium text-gray-700">
                <li className="hover:text-gray-900">
                  <Link to="/">Home</Link>
                </li>
                <li className="hover:text-gray-900">
                  <Link to="/about">About Us</Link>
                </li>
                <li className="hover:text-gray-900">
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>

            <div className="lg:hidden">
              <button onClick={() => setMobileNavOpen(!mobileNavOpen)}>
                <svg
                  className="text-[#134E4A]"
                  width="51"
                  height="51"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="56"
                    height="56"
                    rx="28"
                    fill="currentColor"
                  ></rect>
                  <path
                    d="M37 32H19M37 24H19"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Navigation with Smooth Transition */}
      <div
        className={`fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50 bg-white transform transition-transform duration-300 ease-in-out
          ${mobileNavOpen ? 'translate-x-0' : '-translate-x-full'}`}
      >
        <div
          onClick={() => setMobileNavOpen(false)}
          className={`fixed inset-0 bg-gray-800 transition-opacity duration-300 ease-in-out ${mobileNavOpen ? 'opacity-80' : 'opacity-0 pointer-events-none'}`}
        ></div>
        <nav className="relative z-10 px-9 pt-8 h-full overflow-y-auto">
          <div className="flex flex-col h-full">
            <div className="flex justify-between items-center mb-8">
              <Link to="/" onClick={() => setMobileNavOpen(false)}>
                <img
                  src="https://template.creativemox.com/guardibugs/wp-content/uploads/sites/27/2024/07/exterminator-in-protective-mask-and-uniform-holdin-2-1.png"
                  alt="Aniruddha Pest Control"
                  className="w-12 h-12"
                />
              </Link>
              <button onClick={() => setMobileNavOpen(false)}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 18L18 6M6 6L18 18"
                    stroke="#111827"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
            <ul className="space-y-12 font-medium text-gray-700">
              <li onClick={() => setMobileNavOpen(false)}>
                <Link to="/">Home</Link>
              </li>
              <li onClick={() => setMobileNavOpen(false)}>
                <Link to="/about">About Us</Link>
              </li>
              <li onClick={() => setMobileNavOpen(false)}>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Header;
