import React from "react";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import WhatsappConnect from "./WhatsappConnect";

const Layout = () => {
  return (
    <div className="realtive h-full w-full ">
      <Header />
      <Outlet />
      <Footer />
      <WhatsappConnect/>
    </div>
  );
};

export default Layout;
